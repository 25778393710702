import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { toUTCDDMMYYYY } from '../../../../utils/dateUtils';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 

const DadosAccordion = () => {

    const { linhaSelecionada, showEnfest, gradeSelecionada } = useDistribuicaoDemanda();

    return (
        <section>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Dados da Linha Selecionada</Accordion.Header>
                    <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlockColumm}>
                            <span>Data</span>
                            <span className={styles.fontBlue}>{toUTCDDMMYYYY(linhaSelecionada.DataPriorizacao)}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Ordem Serviço</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.OrdemServico}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Produto</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.Produto}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Material</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.Material}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Cor Material</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.CorMaterial}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Turno</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.TURNO}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Modo Corte</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.ModoCorte}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Grade</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.Grade}</span>
                        </div>
                        { showEnfest &&
                            <div className={styles.infoBlockColumm}>
                                <span>Tamanho Selecionado</span>
                                <span className={styles.fontBlue}>{gradeSelecionada.GRADE}</span>
                            </div>                              
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
};

export default DadosAccordion;
