import React, { useEffect, useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { api }  from "../../services/api";
import styles from '../../styles/MP/LaboratorioCemPorcentoRetorno.module.scss';
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select';
import ResultadoTestes from '../../components/MP/LaboratorioCemPorcentoRetorno/ResultadoTestes';

const LaboratorioCemPorcentoRetorno = () => {
  const [acaoSelecionada, setAcaoSelecionada] = useState({ label: '100%', value: 'cemPorcento' });

  const [filtroBusca, setFiltroBusca] = useState({
    pedido:'',
    material:'',
    cor_material:'',
    op:'',
    os:''
  });
  
  const [busca, setBusca] = useState([]);
  const [linhaSelecionada, setLinhaSelecionada] = useState({});
  const [pecas, setPecas] = useState([]);

  const [showResultadoTestes, setShowResultadoTestes] = useState(false);

  const selecionarAcao = [
		{ label: '100%', value: 'cemPorcento' },
		{ label: 'Retorno', value: 'retorno' }
	]

  const filtroHandler = (event, field) => {
    const filtro = {...filtroBusca};
    filtro[field] = event.target.value;
    setFiltroBusca(filtro);
  }

  const handleShowResultadoTestes = (linha) => {
    setLinhaSelecionada(linha);
    handlePecas(linha);
    setShowResultadoTestes(true);
  }

  const handleSearch = async () => {
    const acao = acaoSelecionada['value'] === 'cemPorcento' ? 0 : 1;
    const info = { ...filtroBusca, acao };
    let variablesToCheck;
    
    if (acao === 0){
      variablesToCheck = ['pedido', 'material', 'cor_material']
    } else if (acao === 1) {
      variablesToCheck = ['op', 'os']
    }
    const fieldEmpty = variablesToCheck.some(variable => !info[variable] || info[variable] === '0');

    if(fieldEmpty){
      toast.warning('Campo(s) obrigatório(s) não preenchido(s)!');
      return;
    }

    acao === 0 ?
      await api.get('MP/LaboratorioCemPorcentoRetorno/get-cemporcento', { params: info })
      .then(res => {
        setBusca(res.data)
        if(res.data.length > 0) toast.success('Busca realizada !')
        else toast.warn('A busca não encontrou resultados')
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
    :
      await api.get('MP/LaboratorioCemPorcentoRetorno/get-retorno', { params: info })
      .then(res => {
        setBusca(res.data)
        if(res.data.length > 0) toast.success('Busca realizada !')
        else toast.warn('A busca não encontrou resultados')
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handlePecas = async (linha) => {
    const acao = acaoSelecionada['value'] === 'cemPorcento' ? 0 : 1;
    const info = { ...linha, acao };

    await api.get('MP/LaboratorioCemPorcentoRetorno/get-pecas', { params: info })
    .then(res => {
      setPecas(res.data)
    }).catch(e => {
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    })
  }
  
  useEffect(() => {
    setFiltroBusca({
      pedido:'',
      material:'',
      cor_material:'',
      op:'',
      os:''
    });
    setBusca([]);
  }, [acaoSelecionada]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <h3  style={{ 'margin': '20px' }}>Laboratório - 100% / Retorno</h3>

      <article className={styles.headerWrapper}>

          <article className={styles.actionWrapper}>
              <Form className={styles.form}>
                  <Form.Group className={styles.formGroup}>
                      <Form.Label className={styles.formLabel}>Ação</Form.Label>
                      <Select
                          closeMenuOnSelect={true}
                          backspaceRemovesValue={true}
                          placeholder={'Selecionar'}
                          value={acaoSelecionada}
                          options={selecionarAcao}
                          onChange={(e) => setAcaoSelecionada(e)}
                      />
                  </Form.Group>
              </Form>
          </article>

          <article className={styles.formWrapper}>

              {/* ACAO === 'cemPorcento' */}
              <div
                  style={{ display: acaoSelecionada?.value === 'cemPorcento' ? 'inline' : 'none' }}
              >
                  <Form className={styles.form}>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Pedido*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Pedido"
                              value={filtroBusca.pedido}
                              onChange={(e) => filtroHandler(e, 'pedido')}
                          />
                      </Form.Group>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Material*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Material"
                              value={filtroBusca.material}
                              onChange={(e) => filtroHandler(e, 'material')}
                          />
                      </Form.Group>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Cor Material*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Cor Material"
                              value={filtroBusca.cor_material}
                              onChange={(e) => filtroHandler(e, 'cor_material')}
                          />
                      </Form.Group>
                      <Button
                          className={styles.formGroup}
                          variant="primary"
                          type="button"
                          onClick={handleSearch}
                      >
                          Buscar
                      </Button>
                  </Form>
              </div>

              {/* ACAO === 'retorno' */}
              <div
                  style={{ display: acaoSelecionada?.value !== 'cemPorcento' ? 'inline' : 'none' }}
              >
                  <Form className={styles.form}>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Ordem de Produção*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Ordem de Produção"
                              value={filtroBusca.op}
                              onChange={(e) => filtroHandler(e, 'op')}
                          />
                      </Form.Group>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Ordem de Serviço*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Ordem de Serviço"
                              value={filtroBusca.os}
                              onChange={(e) => filtroHandler(e, 'os')}
                          />
                      </Form.Group>
                      <Button
                          className={styles.formGroup}
                          variant="primary"
                          type="button"
                          onClick={handleSearch}
                      >
                          Buscar
                      </Button>
                  </Form>
              </div>
          </article>
      </article>

      <article className={styles.bodyWrapper}>
        <article className={styles.articleGrayAlt}>
          <Table responsive>
            <thead>
              <tr>
                <th className={styles.blockTitle}></th>
                <th className={styles.blockTitle}>PEDIDO</th>
                <th className={styles.blockTitle}>NF ENTRADA</th>
                <th className={styles.blockTitle}>FORNECEDOR</th>
                <th className={styles.blockTitle}>MATERIAL</th>
                <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                <th className={styles.blockTitle}>COR MATERIAL</th>
                <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                <th className={styles.blockTitle}>METRAGEM</th>
              </tr>
            </thead>
            <tbody>
            { busca.map((item, index) => { 
                  return (
                  <tr className={styles.tableLines} key={index}>
                    <td className={styles.tableLines}>
                      <Button 
                        variant='warning'
                        onClick={() => handleShowResultadoTestes(item)}
                      >
                        Resultado
                      </Button>
                    </td>
                    <td className={styles.tableLines}>{item.pedido}</td>
                    <td className={styles.tableLines}>{item.nf_entrada}</td>
                    <td className={styles.tableLines}>{item.fornecedor}</td>
                    <td className={styles.tableLines}>{item.material}</td>
                    <td className={styles.tableLines}>{item.desc_material}</td>
                    <td className={styles.tableLines}>{item.cor_material}</td>
                    <td className={styles.tableLines}>{item.desc_cor}</td>
                    <td className={styles.tableLines}>{item.metragem} m</td>
                  </tr>
                )})}
            </tbody>
          </Table>
        </article>
      </article>

      <ResultadoTestes
        acaoSelecionada={acaoSelecionada}
        showResultadoTestes={showResultadoTestes}
        setShowResultadoTestes={setShowResultadoTestes}
        linhaSelecionada={linhaSelecionada}
        setLinhaSelecionada={setLinhaSelecionada}
        pecas={pecas}
        setPecas={setPecas}
        setBusca={setBusca}
      />
    </div>
  );
};

export default LaboratorioCemPorcentoRetorno;