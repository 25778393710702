import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { useAcompanhamentoEnfesto } from '../../../../contexts/Corte/ControleEnfesto/AcompanhamentoEnfestoContext'; 

const MainTable = () => {

    const { columns, loading, handleShowInfo, filtro, mainData } = useAcompanhamentoEnfesto();

    const conditionalRowStyles = [
        {
          when: row => row.Atraso === 'SIM',
          style: { backgroundColor: '#F05A7E' },
        },
    ];

    const filteredItems = mainData.filter(item => 
        item.OrdemProducao.includes(filtro) ||
        item.OrdemServico.includes(filtro) ||
        item.Operador1.toLowerCase().includes(filtro.toLowerCase()) ||
        item.Operador2.toLowerCase().includes(filtro.toLowerCase()) 
    );

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                columns={columns}
                data={filteredItems}
                pending={loading}
                rowClickedFn={handleShowInfo}
                conditionalRowStyles={conditionalRowStyles}
            />
        </section>
    );
};

export default MainTable;
