import React from 'react';
// import { useDistribuicaoDemanda } from '../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext';
import styles from '../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import MainTable from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/MainTable';
// import { Button } from 'react-bootstrap';
import ModalGrade from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalGrade';
import ModalEnfest from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalEnfest';
import ModalAlteraFolha from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalAlteraFolha';
import ModalConfirmacao from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalConfirmacao';

const DistribuicaoDemanda = () => {

    // const {  } = useDistribuicaoDemanda();
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Distribuicao de Demanda</h3>
            </article>

            <article className={styles.articleGray}>
            <span>
                    {/* <Button
                        variant="outline-info"
                        className={styles.modalBtn}
                        style={{ margin: '20px 20px' }}
                        // onClick={handleShowSelecaoFiltro}
                    >
                        Filtro
                    </Button>
                    <Button
                        variant="outline-warning"
                        className={styles.modalBtn}
                        style={{ margin: '20px 20px' }}
                        // onClick={clearAllFiltros}
                    >
                        Limpar Filtro
                    </Button> */}
                </span>
                <MainTable/>
            </article>

            <ModalGrade/>
            <ModalAlteraFolha/>
            <ModalEnfest/>
            <ModalConfirmacao/>
        </div>
    );
};

export default DistribuicaoDemanda;
