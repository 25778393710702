import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import CookiesService from '../../../services/cookies';
import { toDatetime, toUTCDDMMYYYY } from '../../../utils/dateUtils';
import { useMisc } from '../../MiscContext';

const EnfestoContext = createContext();
export const useEnfesto = () => useContext(EnfestoContext);

export const EnfestoProvider = ({ children }) => {

  const { setShowLoading } = useMisc();

  const columns = [
    { name: 'SOLICITANTE', selector: row => row.Solicitante.toUpperCase(), atr: 'Solicitante' },
    { name: 'DATA SOLICITAÇÃO', selector: row => toUTCDDMMYYYY(row.DataSolicitacao), atr: 'DataSolicitacao', width: '200px' },
    { name: 'ORDEM PRODUÇÃO', selector: row => row.OrdemProducao, atr: 'OrdemProducao', width: '200px' },
    { name: 'ORDEM SERVIÇO', selector: row => row.OrdemServico, atr: 'OrdemServico', width: '200px' },
    { name: 'MATERIAL', selector: row => row.Material, atr: 'Material', width: '200px' },
    { name: 'DESCRIÇÃO MATERIAL', selector: row => row.Desc_Material, atr: 'Desc_Material', width: '300px' },
    { name: 'COR MATERIAL', selector: row => row.CorMaterial, atr: 'CorMaterial', width: '200px' },
    { name: 'DESCRIÇÃO COR MATERIAL', selector: row => row.Desc_Cor_Material, atr: 'Desc_Cor_Material', width: '400px' },
    { name: 'GRADE', selector: row => row.Grade, atr: 'Grade', width: '100px' },
    { name: 'COMPRIMENTO RISCO', selector: row => row.ComprimentoRiscoM, atr: 'ComprimentoRiscoM', width: '200px' },
    { name: 'QTDE FOLHAS ALOCADA', selector: row => row.QtdeFolhasAlocada, atr: 'QtdeFolhasAlocada', width: '200px' },
    { name: 'TEMPO ENFESTO', selector: row => row.TempoEnfesto, atr: 'TempoEnfesto', width: '200px' },
    { name: 'OPERADOR 1', selector: row => row.Operador1, atr: 'Operador1', width: '200px' },
    { name: 'OPERADOR 2', selector: row => row.Operador2, atr: 'Operador2', width: '200px' }
  ];

  const user = CookiesService.getUserLogin();

  const [loading, setLoading] = useState(false);

  const [mainData, setMainData] = useState([])
  const [iniciadoData, setIniciadoData] = useState([])
  const [motivoPausaData, setMotivoPausaData] = useState([])

  const [isIniciado, setIsIniciado] = useState(false)
  const [isPausado, setIsPausado] = useState(false)

  const [linhaSelecionada, setLinhaSelecionada] = useState({})
  const [motivoPausaSelecionado, setMotivoPausaSelecionado] = useState({})

  const [showInicia, setShowInicia] = useState(false)
  const [showPausa, setShowPausa] = useState(false)
  const [showFolhas, setShowFolhas] = useState(false)

  const [folhasRealizadas, setFolhasRealizadas] = useState('')

  const handleShowInicia = (row) => {
    setLinhaSelecionada(row)
    setShowInicia(true)
  }

  const handleCloseInicia = () => {
    setLinhaSelecionada({})
    setShowInicia(false)
  }

  const handleShowPausa = () => {
    setShowPausa(true)
  }

  const handleClosePausa = () => {
    setShowPausa(false)
    setMotivoPausaSelecionado({})
  }

  const handleShowFolhas = () => {
    setShowFolhas(true)
  }

  const handleCloseFolhas = () => {
    setShowFolhas(false)
    setFolhasRealizadas('')
  }

  const handleFolhasRealizadas = (e) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const folhas = parseInt(e.target.value)
    setFolhasRealizadas(folhas)
  }

  const initEnfesto = async () => {
    const params = user;
    setLoading(true);
    try {
      const res = await api.get('Corte/ControleEnfesto/Enfesto/get-rows', { params });
      setMainData(res.data.resPrincipal);
      setIniciadoData(res.data.resIniciado);
      setMotivoPausaData(res.data.resMotivoPausa);
    } catch (e) {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setLoading(false);
    }
  };

  const handleIniciaEnfesto = async () => {
    const params = {
      IdDuplaEnfesto: linhaSelecionada.Id,
      DataInicio: toDatetime(new Date())
    };
    setShowLoading(true);
    try {
      const res = await api.post('Corte/ControleEnfesto/Enfesto/inicia-enfesto', { params });
      if (res.status === 200) {
        setShowInicia(false)
        initEnfesto()
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
        toast.error('Ocorreu um erro ao atualizar os dados, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setShowLoading(false);
    }
  }

  const handlePausaEnfesto = async () => {
    const params = {
      info : {
        IdDuplaEnfesto: linhaSelecionada.Id,
        DataInicio: toDatetime(new Date())
      },
      pausa: motivoPausaSelecionado
    };
    setShowLoading(true);
    try {
      const res = await api.post('Corte/ControleEnfesto/Enfesto/pausa-enfesto', { params });
      if (res.status === 200) {
        setShowPausa(false)
        initEnfesto()
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
        toast.error('Ocorreu um erro ao atualizar os dados, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setMotivoPausaSelecionado({})
        setShowLoading(false);
    }
  }

  const handleFinalizaPausa = async () => {
    const params = {
      IdDuplaEnfesto: linhaSelecionada.Id,
      DataFim: toDatetime(new Date())
    };
    setShowLoading(true);
    try {
      const res = await api.post('Corte/ControleEnfesto/Enfesto/fim-pausa-enfesto', { params });
      if (res.status === 200) {
        initEnfesto()
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
        toast.error('Ocorreu um erro ao atualizar os dados, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setShowLoading(false);
    }
  }

  const handleFinalizaEnfesto = async () => {
    if (!folhasRealizadas) {
      toast.warning('É necessário inserir as folhas antes de finalizar o processo.'); 
      return
    }

    const params = {
      IdDuplaEnfesto: linhaSelecionada.Id,
      DataFim: toDatetime(new Date()),
      FolhasRealizadas: folhasRealizadas
    };
    setShowLoading(true);
    try {
      const res = await api.post('Corte/ControleEnfesto/Enfesto/finaliza-enfesto', { params });
      if (res.status === 200) {
        setShowFolhas(false)
        setIsIniciado(0)
        setIsPausado(0)
        initEnfesto()
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
        toast.error('Ocorreu um erro ao atualizar os dados, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setShowLoading(false);
    }
  }

  useEffect(() => {
      initEnfesto();
    }, []);

  useEffect(() => {
    if (iniciadoData.length > 0) {
      setIsIniciado(true)
      setLinhaSelecionada(iniciadoData[0])
      if (iniciadoData[0].Pausado === 1) setIsPausado(true)
        else setIsPausado(false)
    }
  }, [iniciadoData]);

  return (
    <EnfestoContext.Provider
      value={{ handleFolhasRealizadas, folhasRealizadas, showFolhas, handleShowFolhas, handleCloseFolhas, handleFinalizaPausa, isPausado, setMotivoPausaSelecionado, motivoPausaSelecionado, showPausa, handleClosePausa, handleShowPausa, handleFinalizaEnfesto, handlePausaEnfesto, handleIniciaEnfesto, columns, mainData, loading, handleShowInicia, handleCloseInicia, showInicia, linhaSelecionada, isIniciado, iniciadoData, motivoPausaData }}
    >
      {children}
    </EnfestoContext.Provider>
  )
}