export const PROBLEMA_DETECTADO = [
  "Aberta Incorretamente",
  "Alongamento",
  "Defeito Físico",
  "Encolhimento",
  "Estampa Torta",
  "Estampa/ Cor divergente da piloto ou desenv",
  "Fio Torto",
  "Fio Virado",
  "Largura",
  "Migração/Manchas",
  "Rapport",
  "Tecido Incorreto",
  "Tecido Rasgando",
  "Tecido Sujo",
  "Tonalidade",
]

export const TIPO_DE_PROBLEMA_DETECTADO = {
  "Tonalidade": [ 
    "Tecido",
    "Partes Cortadas"],
  "Defeito Físico": [
    "Tecido",
    "Partes Cortadas",
    "Peça Pronta",
    "Após Lavandeira"
  ],
  "Estampa Torta": [
    "Tecido",
    "Peça Pronta",
  ],
  "Fio Virado": [
    "Partes Cortadas",
    "Durante a costura",
    "Peça Pronta",
  ],
  "Tecido Incorreto": [
    "Tecido"
  ],
  "Tecido Sujo": [
    "Tecido"
  ],
  "Rapport": [
    "Variação do Rapport"
  ],
  "Fio Torto": [
    "Tecido",
    "Partes Cortadas",
  ],
  "Encolhimento": [
    "Partes Cortadas",
    "Após Lavandeira"
  ],
  "Largura": [
    "Variação da largura"
  ],
  "Estampa/ Cor divergente da piloto ou desenv": [
    "Entre rolos",
    "Todos os Rolos da Cor/Estampa"
  ],
  "Migração/Manchas": [
    "Após Lavandeira",
    "Peça Pronta",
    "Tecido",
  ],
  "Aberta Incorretamente": [
    "Chamado referente à outra área"
  ],
  "Alongamento": [
    "Tecido"
  ],
  "Tecido Rasgando": [
    "Após Lavandeira"
  ],
}

export const AreaResponsavel = [
  "CQMP",
  "Corte",
  "CAD",
  "Logística",
  "Ateliê",
  "Marca",
  "Outros"
]