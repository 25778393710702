import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import styles from '../styles/RevisaoLaboratorio.module.scss';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import { toast, ToastContainer } from "react-toastify";
import { api }  from "../services/api";
import { toDatetime } from '../utils/dateUtils';
import CookiesService from "../services/cookies";

const IdUsuario = CookiesService.getUserLogin();

const RevisaoLaboratorio = () => {
  const [acaoSelecionada, setAcaoSelecionada] = useState({ 
    label: '100%', 
    value: 'cemPorcento' 
  });

  const [filtroBusca, setFiltroBusca] = useState({
    nf:'',
    material:'',
    cor_material:'',
    pedido:'',
    os:''
  });

  const [busca, setBusca] = useState([]);
  const [pecas, setPecas] = useState([]);
  const [testes, setTestes] = useState([]);
  const [linhaSelecionada, setLinhaSelecionada] = useState({});

  const [cemPorcentoRevisaoPadrao, setCemPorcentoRevisaoPadrao] = useState({
    DataRegistro: '',
    NfEntrada: '',
    SerieNf: '',
    Pedido: '',
    Fornecedor: '',
    Material: '',
    CorMaterial: '',
    Peca: '',
    Quantidade: ''
  });
  const [cemPorcentoRevisaoArr, setCemPorcentoRevisaoArr] = useState([]);
  const [selectAllCemPorcentoRevisao, setSelectAllCemPorcentoRevisao] = useState(false);

  const cemPorcentoLabPadrao = ({
    DataRegistro: '',
    IdUsuarioRegistro: '',
    Peca: '',
    IdTeste: ''
  });
  const [cemPorcentoLabArr, setCemPorcentoLabArr] = useState([]);
  const [cemPorcentoLabPecaEscolhida, setCemPorcentoLabPecaEscolhida] = useState('');
  const [cemPorcentoTestesSelecionados, setCemPorcentoTestesSelecionados] = useState([])
  const [cemPorcentoPecasIncluidas, setCemPorcentoPecasIncluidas] = useState([]);

  const [retornoRevisaoPadrao, setRetornoRevisaoPadrao] = useState({
    DataRegistro: '',
    OrdemProducao: '',
    OrdemServico: '',
    Material: '',
    CorMaterial: '',
    Peca: '',
    Quantidade: ''
  });
  const [retornoRevisaoArr, setRetornoRevisaoArr] = useState([]);
  const [selectAllRetornoRevisao, setSelectAllRetornoRevisao] = useState(false);

  const retornoLaboratorioPadrao = ({
    DataRegistro: '',
    IdUsuarioRegistro: '',
    OrdemProducao: '',
    OrdemServico: '',
    Material: '',
    Peca: '',
    IdTeste: ''
  })
  const [materiaisRetornoLab, setMateriaisRetornoLab ] = useState([]);
  const [pecasRetornoLab, setPecasRetornoLab] = useState([]);
  const [retornoLabMaterialEscolhido, setRetornoLabMaterialEscolhido] = useState({});
  const [retornoLabPecaEscolhida, setRetornoLabPecaEscolhida] = useState({});
  const [retornoTestesSelecionados, setRetornoTestesSelecionados] = useState([])
  const [retornoPecasIncluidas, setRetornoPecasIncluidas] = useState([]);
  const [retornoLabArr, setRetornoLabArr] = useState([]);

  const [showModalCemPorcentoRevisao, setShowModalCemPorcentoRevisao] = useState(false);
  const [showModalCemPorcentoLaboratorio, setShowModalCemPorcentoLaboratorio] = useState(false);
  const [showModalRetornoRevisao, setShowModalRetornoRevisao] = useState(false);
  const [showModalRetornoLaboratorio, setShowModalRetornoLaboratorio] = useState(false);

  const selecionarAcao = [
		{ label: '100%', value: 'cemPorcento' },
		{ label: 'Retorno', value: 'retorno' }
	]

  const handleCloseCemPorcentoRevisao = () => {
    setPecas([]);
    setCemPorcentoRevisaoPadrao({
      DataRegistro: '',
      NfEntrada: '',
      SerieNf: '',
      Pedido: '',
      Fornecedor: '',
      Material: '',
      CorMaterial: '',
      Peca: '',
      Quantidade: ''
    });
    setCemPorcentoRevisaoArr([]);
    setShowModalCemPorcentoRevisao(false);
    setLinhaSelecionada({});
  }

	const handleShowModalCemPorcentoRevisao = (linha) => {
    handlePecas(linha);
    handleCemPorcentoRevisao(linha);
		setShowModalCemPorcentoRevisao(true);
    setLinhaSelecionada(linha);
	}

  const handleCloseCemPorcentoLaboratorio = () => {
    setPecas([]);
    setCemPorcentoLabPecaEscolhida('');
    setCemPorcentoTestesSelecionados([]);
    setCemPorcentoLabArr([]);
    setCemPorcentoPecasIncluidas([]);
    setShowModalCemPorcentoLaboratorio(false);
    setLinhaSelecionada({});
  }

	const handleShowModalCemPorcentoLaboratorio = (linha) => {
    handlePecas(linha);
		setShowModalCemPorcentoLaboratorio(true);
    setLinhaSelecionada(linha);
	}

  const handleCloseRetornoRevisao = () => {
    setPecas([]);
    setRetornoRevisaoPadrao({
      DataRegistro: '',
      OrdemProducao: '',
      OrdemServico: '',
      Material: '',
      CorMaterial: '',
      Peca: '',
      Quantidade: ''
    });
    setRetornoRevisaoArr([]);
    setShowModalRetornoRevisao(false); 
    setLinhaSelecionada({});
  }

	const handleShowModalRetornoRevisao = (linha) => {
    handlePecas(linha);
    handleRetornoRevisao(linha);
		setShowModalRetornoRevisao(true);
    setLinhaSelecionada(linha);
	}

  const handleCloseRetornoLaboratorio = () => {
    setMateriaisRetornoLab([]);
    setPecasRetornoLab([]);
    setRetornoLabArr([]);
    setRetornoPecasIncluidas([]);
    setRetornoLabMaterialEscolhido({});
    setRetornoLabPecaEscolhida({});
    setRetornoTestesSelecionados([]);
    setShowModalRetornoLaboratorio(false);
    setLinhaSelecionada({});
  }

	const handleShowModalRetornoLaboratorio = (linha) => {
    handleMateriaisRetornoLab(linha)
		setShowModalRetornoLaboratorio(true);
    setLinhaSelecionada(linha);
	}

  const handleCemPorcentoRevisao = (linha) => {
    setCemPorcentoRevisaoPadrao({
      ...cemPorcentoRevisaoPadrao,
      DataRegistro: toDatetime(new Date()),
      NfEntrada: linha.nf_entrada,
      SerieNf: linha.serie_nf,
      Pedido: linha.pedido,
      Fornecedor: linha.fornecedor,
      Material: linha.material,
      CorMaterial: linha.cor_material
    })
  }

  const handleRetornoRevisao = (linha) => {
    setRetornoRevisaoPadrao({
      ...retornoRevisaoPadrao,
      DataRegistro: toDatetime(new Date()),
      OrdemProducao: linha.ordem_producao,
      OrdemServico: linha.ordem_servico,
      Material: linha.material,
      CorMaterial: linha.cor_material
    })
  }

  const handleSearch = async () => {
    const acao = acaoSelecionada['value'] === 'cemPorcento' ? 0 : 1
    const info = {...filtroBusca, acao}

    await api.get('MPRevisaoLaboratorio/get-lista', { params: info })
      .then(res => {
        setBusca(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handlePecas = async (params) => {
    const acao = acaoSelecionada['value'] === 'cemPorcento' ? 0 : 1
    const info = {...params, acao}

    await api.get('MPRevisaoLaboratorio/get-pecas', { params: info })
      .then(res => {
        setPecas(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handleMateriaisRetornoLab = async (params) => {
    await api.get('MPRevisaoLaboratorio/get-materiaisRetLab', { params })
      .then(res => {
        setMateriaisRetornoLab(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handlePecasRetornoLab = async (params) => {
    await api.get('MPRevisaoLaboratorio/get-pecasRetLab', { params })
      .then(res => {
        setPecasRetornoLab(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
      
  }

  const handleTestes = async () => {
    await api.get('MPRevisaoLaboratorio/get-testes')
      .then(res => {
        setTestes(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handleProcessarCemPorcentoRevisao = async () => {
    if (cemPorcentoRevisaoArr.length === 0) {
      toast.warning('É necessário selecionar, ao menos, uma peça antes de continuar.')
      return
    }

    const send = { cemPorcento: { nf: linhaSelecionada.nf_entrada
                                , pedido: linhaSelecionada.pedido
                                , material: linhaSelecionada.material
                                , cor_material: linhaSelecionada.cor_material },
                   revisao: cemPorcentoRevisaoArr }
    await api.post('MPRevisaoLaboratorio/process-cemPorcentoRevisao', { send })
    .then(res => {
      if (res.status === 200) { 
          toast.success('Dados inseridos com sucesso!'); 
          handleCloseCemPorcentoRevisao();
          setBusca([]);
          setFiltroBusca({
            nf:'',
            material:'',
            cor_material:'',
            pedido:'',
            os:''
          });
      }
      else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    }).catch(e => {
      toast.error(
          `Entre em contato com o suporte. ${e}`
      );
      console.error(e)
    });
  }

  const handleProcessarCemPorcentoLab = async () => {
    if (cemPorcentoPecasIncluidas.length === 0) {
      toast.warning('É necessário selecionar, ao menos, uma peça antes de continuar.')
      return
    }

    const send = { cemPorcento: { nf: linhaSelecionada.nf_entrada
                                , pedido: linhaSelecionada.pedido
                                , material: linhaSelecionada.material
                                , cor_material: linhaSelecionada.cor_material }, 
                   lab: cemPorcentoPecasIncluidas}
    await api.post('MPRevisaoLaboratorio/process-cemPorcentoLaboratorio', { send })
    .then(res => {
      if (res.status === 200) { 
          toast.success('Dados inseridos com sucesso!'); 
          handleCloseCemPorcentoLaboratorio();
          setBusca([]);
          setFiltroBusca({
            nf:'',
            material:'',
            cor_material:'',
            pedido:'',
            os:''
          });
      }
      else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    }).catch(e => {
      toast.error(
          `Entre em contato com o suporte. ${e}`
      );
      console.error(e)
    });
  }

  const handleProcessarRetornoRevisao = async () => {
    if (retornoRevisaoArr.length === 0) {
      toast.warning('É necessário selecionar, ao menos, uma peça antes de continuar.')
      return
    }

    const send = { retorno: { os: linhaSelecionada.ordem_servico }, revisao: retornoRevisaoArr }
    await api.post('MPRevisaoLaboratorio/process-retornoRevisao', { send })
    .then(res => {
      if (res.status === 200) { 
          toast.success('Dados inseridos com sucesso!'); 
          handleCloseRetornoRevisao();
          setBusca([]);
          setFiltroBusca({
            nf:'',
            material:'',
            cor_material:'',
            pedido:'',
            os:''
          });
      }
      else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    }).catch(e => {
      toast.error(
          `Entre em contato com o suporte. ${e}`
      );
      console.error(e)
    });
  }

  const handleProcessarRetornoLaboratorio = async () => {
    if (retornoPecasIncluidas.length === 0) {
      toast.warning('É necessário selecionar, ao menos, uma peça antes de continuar.')
      return
    }

    const send = { retorno: { os: linhaSelecionada.ordem_servico }, lab: retornoPecasIncluidas }
    await api.post('MPRevisaoLaboratorio/process-retornoLaboratorio', { send })
    .then(res => {
      if (res.status === 200) { 
          toast.success('Dados inseridos com sucesso!'); 
          handleCloseRetornoLaboratorio();
          setBusca([]);
          setFiltroBusca({
            nf:'',
            material:'',
            cor_material:'',
            pedido:'',
            os:''
          });
      }
      else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    }).catch(e => {
      toast.error(
          `Entre em contato com o suporte. ${e}`
      );
      console.error(e)
    });
  }

  const handleCheckAllCemPorcentoRevisao = (e) => {
    let fullArr = [];
    pecas.forEach((item) => {
      fullArr.push({...cemPorcentoRevisaoPadrao, Peca: item.peca, Quantidade: item.metragem})
    })

    const isChecked = e.target.checked;
    setSelectAllCemPorcentoRevisao(isChecked);
    setCemPorcentoRevisaoArr(isChecked 
      ? fullArr
      : []);
  };

  const handleCheckAllRetornoRevisao = (e) => {
    let fullArr = [];
    pecas.forEach((item) => {
      fullArr.push({...retornoRevisaoPadrao
        , Material: item.material
        , CorMaterial: item.cor_material
        , Peca: item.peca
        , Quantidade: item.metragem})
    })

    const isChecked = e.target.checked;
    setSelectAllRetornoRevisao(isChecked);
    setRetornoRevisaoArr(isChecked 
      ? fullArr
      : []);
  };

  const handleCheckCemPorcentoRevisao = (e, linha) => {
    const isChecked = e.target.checked;
    const updatedArray = isChecked
      ? [ ...cemPorcentoRevisaoArr, {...cemPorcentoRevisaoPadrao, Peca: linha.peca, Quantidade: linha.metragem}]
      : cemPorcentoRevisaoArr.filter(item => item.Peca !== linha.peca)

    setCemPorcentoRevisaoArr(updatedArray)
  }

  const handleCheckPecaCemPorcentoLab = (e, peca) => {
    const isChecked = e.target.checked;
    const pecaChecked = isChecked ? peca.peca : null
    
    setCemPorcentoLabPecaEscolhida(pecaChecked)
  }

  const handleCheckTesteCemPorcentoLab = (e, teste) => {
    if (!cemPorcentoLabPecaEscolhida) {
      toast.warning('Escolha uma peça antes de marcar os testes!');
      return
    }

    const isChecked = e.target.checked;

    const arrayTestes = isChecked 
      ? [ ...cemPorcentoTestesSelecionados, teste ]
      : cemPorcentoTestesSelecionados.filter(item => item.id_teste !== teste.id_teste)

      const updatedArray = isChecked 
      ? [ ...cemPorcentoLabArr, {
        ...cemPorcentoLabPadrao
        , DataRegistro: toDatetime(new Date())
        , IdUsuarioRegistro: IdUsuario
        , Peca: cemPorcentoLabPecaEscolhida
        , IdTeste: teste.id_teste
    }] 
      : cemPorcentoLabArr.filter(item => item.IdTeste !== teste.id_teste)

    setCemPorcentoLabArr(updatedArray)
    setCemPorcentoTestesSelecionados(arrayTestes);
  }

  const incluirPecaCemPorcentoLab = () => {
    if (!cemPorcentoLabPecaEscolhida) {
      toast.warning('Escolha uma peça antes de marcar os testes!');
      return
    } else if (!cemPorcentoTestesSelecionados){
      toast.warning('Escolha os testes antes de incluir a peça!');
      return
    }

    setCemPorcentoPecasIncluidas([...cemPorcentoPecasIncluidas, ...cemPorcentoLabArr])
    setPecas(pecas.filter(item => item.peca !== cemPorcentoLabPecaEscolhida))
    setCemPorcentoLabPecaEscolhida('');
    setCemPorcentoTestesSelecionados([]);
    setCemPorcentoLabArr([]);
  }

  const handleCheckRetornoRevisao = (e, linha) => {
    const isChecked = e.target.checked;
    const updatedArray = isChecked
      ? [ ...retornoRevisaoArr, {
        ...retornoRevisaoPadrao
        , Material: linha.material
        , CorMaterial: linha.cor_material
        , Peca: linha.peca
        , Quantidade: linha.metragem
      }]
      : retornoRevisaoArr.filter(item => item.Peca !== linha.peca)

    setRetornoRevisaoArr(updatedArray)
  }

  const handleDeletePecaCemPorcentoLab = (item) => {
    setCemPorcentoPecasIncluidas(cemPorcentoPecasIncluidas.filter(peca => peca.Peca !== item.Peca))
    setPecas([...pecas, {peca: item.Peca}])
    setCemPorcentoLabPecaEscolhida('');
    setCemPorcentoLabArr([]);
  }

  const handleDeletePecaRetornoLab = (item) => {
    setRetornoPecasIncluidas(retornoPecasIncluidas.filter(peca => peca.Peca !== item.Peca))
    setRetornoLabMaterialEscolhido({});
    setRetornoLabPecaEscolhida({});
    setRetornoTestesSelecionados([]);
    setRetornoLabArr([]);
    setPecasRetornoLab([]);
  }

  const handleCheckMaterialRetornoLab = (e, mat) => {
    const isChecked = e.target.checked;
    const materialChecked = isChecked ? mat : null
    
    setRetornoLabMaterialEscolhido(materialChecked)

    setRetornoLabPecaEscolhida({});
    setPecasRetornoLab([]);
    setRetornoTestesSelecionados([]);
  }

  const handleCheckPecaRetornoLab = (e, pec) => {
    if (retornoPecasIncluidas.find(el => el.Peca === pec.peca)) {
      toast.warning('Peça já incluída! Caso queira alterar, favor deletar na área abaixo!')
      return
    }

    const isChecked = e.target.checked;
    const pecaChecked = isChecked ? pec : null
    
    setRetornoLabPecaEscolhida(pecaChecked)
  }

  const handleCheckTesteRetornoLab = (e, teste) => {
    if (Object.keys(retornoLabPecaEscolhida).length === 0) {
      toast.warning('Escolha uma peça antes de marcar os testes!');
      return
    }

    const isChecked = e.target.checked;

    const arrayTestes = isChecked 
      ? [ ...retornoTestesSelecionados, teste ]
      : retornoTestesSelecionados.filter(item => item.id_teste !== teste.id_teste)

      const updatedArray = isChecked 
      ? [ ...retornoLabArr, {
        ...retornoLaboratorioPadrao
        , DataRegistro: toDatetime(new Date())
        , OrdemProducao: retornoLabMaterialEscolhido.ordem_producao
        , OrdemServico: retornoLabMaterialEscolhido.ordem_servico
        , IdUsuarioRegistro: IdUsuario
        , Material: retornoLabMaterialEscolhido.material
        , Peca: retornoLabPecaEscolhida.peca
        , IdTeste: teste.id_teste
    }] 
      : retornoLabArr.filter(item => item.IdTeste !== teste.id_teste)

    setRetornoLabArr(updatedArray)
    setRetornoTestesSelecionados(arrayTestes);
  }

  const incluirPecaRetornoLab = () => {
    if (!retornoLabPecaEscolhida) {
      toast.warning('Escolha uma peça e marque os testes antes de incluir a peça!');
      return
    } else if (!retornoTestesSelecionados){
      toast.warning('Escolha os testes antes de incluir a peça!');
      return
    }

    setRetornoPecasIncluidas([...retornoPecasIncluidas, ...retornoLabArr]);
    setRetornoLabMaterialEscolhido({});
    setRetornoLabPecaEscolhida({});
    setRetornoTestesSelecionados([]);
    setRetornoLabArr([]);
    setPecasRetornoLab([]);
  }

  const filtroHandler = (event, field) => {
    const filtro = {...filtroBusca};
    filtro[field] = event.target.value;
    setFiltroBusca(filtro);
  }

  useEffect(() => {
    setFiltroBusca({
      nf:'',
      material:'',
      cor_material:'',
      pedido:'',
      os:''
    });
    setBusca([]);
  }, [acaoSelecionada]);

  useEffect(() => {
    handleTestes();
    handleSearch();
  }, []);

  useEffect(() => {
    if (cemPorcentoRevisaoArr.length === pecas.length && cemPorcentoRevisaoArr.length !== 0 && pecas.length !== 0){
        setSelectAllCemPorcentoRevisao(true);
    } else setSelectAllCemPorcentoRevisao(false);
  }, [cemPorcentoRevisaoArr]);

  useEffect(() => {
    if (retornoRevisaoArr.length === pecas.length && retornoRevisaoArr.length !== 0 && pecas.length !== 0){
        setSelectAllRetornoRevisao(true);
    } else setSelectAllRetornoRevisao(false);
  }, [retornoRevisaoArr]);

  useEffect(() => {
    setRetornoTestesSelecionados([]);
  }, [retornoLabPecaEscolhida])
    
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <h3  style={{ 'margin': '20px' }}>Revisão/Laboratório</h3>

      <article className={styles.headerWrapper}>

          <article className={styles.actionWrapper}>
              <Form className={styles.form}>
                  <Form.Group className={styles.formGroup}>
                      <Form.Label className={styles.formLabel}>Ação</Form.Label>
                      <Select
                          closeMenuOnSelect={true}
                          backspaceRemovesValue={true}
                          placeholder={'Selecionar'}
                          value={acaoSelecionada}
                          options={selecionarAcao}
                          onChange={(e) => setAcaoSelecionada(e)}
                      />
                  </Form.Group>
              </Form>
          </article>

          <article className={styles.formWrapper}>

              {/* ACAO === 'cemPorcento' */}
              <div
                  style={{ display: acaoSelecionada?.value === 'cemPorcento' ? 'inline' : 'none' }}
              >
                  <Form className={styles.form}>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Nota Fiscal*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Nota Fiscal"
                              value={filtroBusca.nf}
                              onChange={(e) => filtroHandler(e, 'nf')}
                          />
                      </Form.Group>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Material*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Material"
                              value={filtroBusca.material}
                              onChange={(e) => filtroHandler(e, 'material')}
                          />
                      </Form.Group>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Cor Material*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Cor Material"
                              value={filtroBusca.cor_material}
                              onChange={(e) => filtroHandler(e, 'cor_material')}
                          />
                      </Form.Group>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Pedido*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Pedido"
                              value={filtroBusca.pedido}
                              onChange={(e) => filtroHandler(e, 'pedido')}
                          />
                      </Form.Group>
                      <Button
                          className={styles.formGroup}
                          variant="primary"
                          type="button"
                          onClick={handleSearch}
                      >
                          Buscar
                      </Button>
                  </Form>
              </div>

              {/* ACAO !== 'cemPorcento' */}
              <div
                  style={{ display: acaoSelecionada?.value !== 'cemPorcento' ? 'inline' : 'none' }}
              >
                  <Form className={styles.form}>
                      <Form.Group className={styles.formGroup}>
                          <Form.Label className={styles.formLabel}>Ordem de Serviço*</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Ordem de Serviço"
                              value={filtroBusca.os}
                              onChange={(e) => filtroHandler(e, 'os')}
                          />
                      </Form.Group>
                      <Button
                          className={styles.formGroup}
                          variant="primary"
                          type="button"
                          onClick={handleSearch}
                      >
                          Buscar
                      </Button>
                  </Form>
              </div>
          </article>
      </article>

      <article className={styles.bodyWrapper}>
        {/* ACAO === 'cemPorcento' */}
        <div
          style={{ display: acaoSelecionada?.value === 'cemPorcento' ? 'inline' : 'none' }}
        >
        <article className={styles.articleGrayAlt}>
          <Table responsive>
            <thead>
              <tr>
                <th className={styles.blockTitle}></th>
                <th className={styles.blockTitle}></th>
                <th className={styles.blockTitle}>FORNECEDOR</th>
                <th className={styles.blockTitle}>NF ENTRADA</th>
                <th className={styles.blockTitle}>PEDIDO</th>
                <th className={styles.blockTitle}>MATERIAL</th>
                <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                <th className={styles.blockTitle}>COR MATERIAL</th>
                <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                <th className={styles.blockTitle}>QTDE ENTRADA</th>
                <th className={styles.blockTitle}>TIPO COMPRA</th>
              </tr>
            </thead>
            <tbody>   
              { acaoSelecionada?.value === 'cemPorcento' ?
                busca.map((item, index) => { 
                  const revisaoIniciada = item.revisao_iniciada
                  const laboratorioIniciada = item.laboratorio_iniciada
                  const agendadoRevisao = item.agendado_revisao
                  const agendadoLaboratorio = item.agendado_laboratorio
                  return (
                  <tr className={styles.tableLines} key={index}>
                    <td className={styles.tableLines}>
                      { revisaoIniciada || !agendadoRevisao ?
                        <Button 
                          variant='secondary'
                          disabled
                        >
                          Revisão
                        </Button>     
                      :
                        <Button 
                          variant='warning'
                          onClick={() => handleShowModalCemPorcentoRevisao(item)}
                        >
                          Revisão
                        </Button>                                        
                      }
                    </td>
                    <td className={styles.tableLines}>
                      { laboratorioIniciada || !agendadoLaboratorio ?
                        <Button 
                          variant='secondary'
                          disabled
                        >
                          Laboratório
                        </Button>                      
                      :
                        <Button 
                          variant='info'
                          onClick={() => handleShowModalCemPorcentoLaboratorio (item)}
                        >
                          Laboratório
                        </Button>                    
                      }
                    </td>
                    <td className={styles.tableLines}>{item.fornecedor}</td>
                    <td className={styles.tableLines}>{item.nf_entrada}</td>
                    <td className={styles.tableLines}>{item.pedido}</td>
                    <td className={styles.tableLines}>{item.material}</td>
                    <td className={styles.tableLines}>{item.descricao_material}</td>
                    <td className={styles.tableLines}>{item.cor_material}</td>
                    <td className={styles.tableLines}>{item.descricao_cor}</td>
                    <td className={styles.tableLines}>{item.qtde_entrada}</td>
                    <td className={styles.tableLines}>{item.tipo_compra}</td>
                  </tr>
                  )}) : null}
            </tbody>
          </Table>
      </article>
        </div>

        {/* ACAO <> 'cemPorcento' */}
        <div 
          style={{ display: acaoSelecionada?.value !== 'cemPorcento' ? 'inline' : 'none' }}
        >
          <article className={styles.articleGrayAlt}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>ORDEM PRODUÇÃO</th>
                  <th className={styles.blockTitle}>ORDEM DE SERVIÇO</th>
                  <th className={styles.blockTitle}>ORIGEM</th>
                  <th className={styles.blockTitle}>MARCA</th>
                  <th className={styles.blockTitle}>PRODUTO</th>
                  <th className={styles.blockTitle}>DESCRIÇÃO PRODUTO</th>
                  <th className={styles.blockTitle}>TIPO ORDEM PRODUÇÃO</th>
                  <th className={styles.blockTitle}>COLEÇÃO</th>
                </tr>
              </thead>
              <tbody>
              { acaoSelecionada?.value === 'retorno' ?
                busca.map((item, index) => { 
                  const revisaoIniciada = item.revisao_iniciada
                  const laboratorioIniciada = item.laboratorio_iniciada
                  const agendadoRevisao = item.agendado_revisao
                  const agendadoLaboratorio = item.agendado_laboratorio
                  return (
                  <tr className={styles.tableLines} key={index}>
                    <td className={styles.tableLines}>
                      { revisaoIniciada || !agendadoRevisao ?
                        <Button 
                          variant='secondary'
                          disabled
                        >
                          Revisão
                        </Button>     
                      :
                        <Button 
                          variant='warning'
                          onClick={() => handleShowModalRetornoRevisao(item)}
                        >
                          Revisão
                        </Button>                                        
                      }
                    </td>
                    <td className={styles.tableLines}>
                      { laboratorioIniciada || !agendadoLaboratorio ?
                        <Button 
                          variant='secondary'
                          disabled
                        >
                          Laboratório
                        </Button>                      
                      :
                        <Button 
                          variant='info'
                          onClick={() => handleShowModalRetornoLaboratorio(item)}
                        >
                          Laboratório
                        </Button>                    
                      }
                      </td>
                      <td className={styles.tableLines}>{item.ordem_producao}</td>
                      <td className={styles.tableLines}>{item.ordem_servico}</td>
                      <td className={styles.tableLines}>{item.origem}</td>
                      <td className={styles.tableLines}>{item.marca}</td>
                      <td className={styles.tableLines}>{item.produto}</td>
                      <td className={styles.tableLines}>{item.descricao_produto}</td>
                      <td className={styles.tableLines}>{item.tipo_op}</td>
                      <td className={styles.tableLines}>{item.colecao}</td>
                    </tr>
                )}) : null}
              </tbody>
            </Table>
          </article>
        </div>
      </article>

      {/* Modal 100% - Revisão*/}
      <Modal
        show={showModalCemPorcentoRevisao}
        onHide={handleCloseCemPorcentoRevisao}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>100% - Revisão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do Pedido</Accordion.Header>
                { busca.length > 0 && acaoSelecionada['value'] === 'cemPorcento' ?
                <Accordion.Body className={styles.infoBlockDisplay}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>NF entrada:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.nf_entrada}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Pedido:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.pedido}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Qtde entrada:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.qtde_entrada}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Fornecedor:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.fornecedor}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Material:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.material}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor Material:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.cor_material}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Tipo compra:</span>
                          <span className={styles.fontBlue}>{linhaSelecionada.tipo_compra}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição Material:</span>
                          <span className={styles.fontBlue}>{linhaSelecionada.descricao_material}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição cor material:</span>
                          <span className={styles.fontBlue}>{linhaSelecionada.descricao_cor}</span>
                      </div>  
                  </div>
                </Accordion.Body> : null 
                }
              </Accordion.Item>
            </Accordion>
          </article>

          <article className={styles.incTableWrapper}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}>
                    <Form>
                      <Form.Check 
                        type='checkbox'
                        onChange={(e) => handleCheckAllCemPorcentoRevisao(e)}
                        checked={selectAllCemPorcentoRevisao}
                      />
                    </Form>
                  </th>
                  <th className={styles.blockTitle}>PEÇA</th>
                  <th className={styles.blockTitle}>QUANTIDADE</th>
                </tr>
              </thead>
              <tbody>
              { pecas.length > 0 && acaoSelecionada['value'] === 'cemPorcento' ? 
                  pecas.map((item, index) => {
                    return (
                      <tr className={styles.tableLines} key={index}>
                        <td className={styles.tableLines}>
                          <Form>
                            <Form.Check 
                                type='checkbox'
                                onChange={(e) => handleCheckCemPorcentoRevisao(e, item)}
                                checked={cemPorcentoRevisaoArr.filter(i => i.Peca === item.peca).length > 0}
                            />
                          </Form>
                        </td>
                        <td className={styles.tableLines}>{item.peca}</td>
                        <td className={styles.tableLines}>{item.metragem}</td>
                      </tr>
                      )}) 
                  : null
                }
              </tbody>
            </Table>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseCemPorcentoRevisao}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className={styles.modalBtn}
            onClick={handleProcessarCemPorcentoRevisao}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal 100% - Laboratório*/}
      <Modal
        show={showModalCemPorcentoLaboratorio}
        onHide={handleCloseCemPorcentoLaboratorio}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>100% - Laboratório</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do Pedido</Accordion.Header>
                { busca.length > 0 && acaoSelecionada['value'] === 'cemPorcento' ?
                <Accordion.Body className={styles.infoBlockDisplay}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>NF entrada:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.nf_entrada}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Pedido:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.pedido}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Qtde entrada:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.qtde_entrada}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Fornecedor:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.fornecedor}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Material:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.material}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor Material:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.cor_material}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Tipo compra:</span>
                          <span className={styles.fontBlue}>{linhaSelecionada.tipo_compra}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição Material:</span>
                          <span className={styles.fontBlue}>{linhaSelecionada.descricao_material}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição cor material:</span>
                          <span className={styles.fontBlue}>{linhaSelecionada.descricao_cor}</span>
                      </div>  
                  </div>
                </Accordion.Body> : null 
                }
              </Accordion.Item>
            </Accordion>
          </article>

          <article className={styles.incTableInsertWrapper}>
            <div className={styles.tablesRow}>
              <div className={styles.tablesColumn}>
                <Form>
                  <Table responsive style={{ "minWidth": '230px'}}>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}></th>
                        <th className={styles.blockTitle}>PEÇA</th>
                      </tr>
                    </thead>
                    <tbody>
                    { pecas.length > 0 && acaoSelecionada['value'] === 'cemPorcento' ? 
                        pecas.map((item, index) => {
                          return (
                            <tr className={styles.tableLines} key={index}>
                              <td className={styles.tableLines}>
                                <Form.Check 
                                  type='radio'
                                  name='pec'
                                  onChange={(e) => handleCheckPecaCemPorcentoLab(e, item)}
                                  checked={cemPorcentoLabPecaEscolhida === item.peca}
                                />
                              </td>
                              <td className={styles.tableLines}>{item.peca}</td>
                            </tr>
                          )}) 
                        : null
                      }
                    </tbody>
                  </Table>
                </Form>
              </div>

              <div className={styles.tablesColumn}>
                <Form>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}></th>
                        <th className={styles.blockTitle}>SELECIONE O TESTE</th>
                      </tr>
                    </thead>
                    <tbody>
                      { testes.map((item, index) => {
                        return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>                     
                            <Form.Check 
                              type='checkbox'
                              name='tst'
                              onChange={(e) => handleCheckTesteCemPorcentoLab(e, item)}
                              checked={cemPorcentoTestesSelecionados.filter(i => i.id_teste === item.id_teste).length > 0}
                            />
                          </td>
                          <td className={styles.tableLines}>{item.descricao_teste}</td>
                        </tr>
                        )}) 
                      }
                    </tbody>
                  </Table>
                </Form>
              </div>

            </div>
            <div style={{marginBottom: "20px"}}>
              <Button 
                variant='success' 
                style={{whiteSpace: "nowrap"}}
                onClick={incluirPecaCemPorcentoLab}
              >Incluir peça</Button>
            </div>              
          </article>

          <article className={styles.incTableWrapper}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>PEÇA</th>
                  <th className={styles.blockTitle}>TESTE</th>
                </tr>
              </thead>
              <tbody>
                { cemPorcentoPecasIncluidas.length > 0 ?
                    cemPorcentoPecasIncluidas.map((item, index) => {
                      const teste = testes.find(el => el.id_teste === item.IdTeste)
                      return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>
                            <Button 
                              style={{ backgroundColor:'white', border: 'white'}}
                              onClick={() => handleDeletePecaCemPorcentoLab(item)}
                            >
                              <MaterialIcon
                                icon="delete" 
                                size={25} 
                                color='#BB271A'
                              />
                            </Button>
                          </td>
                          <td className={styles.tableLines}>{item.Peca}</td>
                          <td className={styles.tableLines}>{teste.descricao_teste}</td>
                        </tr>
                      )})
                  : null
                }
              </tbody>
            </Table>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseCemPorcentoLaboratorio}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className={styles.modalBtn}
            onClick={handleProcessarCemPorcentoLab}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Retorno - Revisão */}
      <Modal
        show={showModalRetornoRevisao}
        onHide={handleCloseRetornoRevisao}
        backdrop="static"
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>Retorno - Revisão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do Pedido</Accordion.Header>
                { busca.length > 0 && acaoSelecionada['value'] === 'retorno' ?
                  <Accordion.Body className={styles.infoBlockDisplay}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Ordem de produção:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.ordem_producao}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Ordem de serviço:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.ordem_servico}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Origem:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.origem}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Produto:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.produto}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Descrição produto:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.descricao_produto}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Tipo ordem produção:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.tipo_op}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Marca:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.marca}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Coleção:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.colecao}</span>
                    </div>
                  </div>
                </Accordion.Body> : null
                }  
              </Accordion.Item>
            </Accordion>
          </article>

          <article className={styles.incTableWrapper}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}>
                    <Form>
                      <Form.Check 
                        type='checkbox'
                        onChange={(e) => handleCheckAllRetornoRevisao(e)}
                        checked={selectAllRetornoRevisao}
                      />
                    </Form>
                  </th>
                  <th className={styles.blockTitle}>PEÇA</th>
                  <th className={styles.blockTitle}>MATERIAL</th>
                  <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                  <th className={styles.blockTitle}>COR MATERIAL</th>
                  <th className={styles.blockTitle}>DESCRIÇÃO COR MATERIAL</th>
                </tr>
              </thead>
              <tbody>
                { pecas.length > 0 && acaoSelecionada['value'] === 'retorno' ? 
                    pecas.map((item, index) => {
                      return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>
                            <Form>
                              <Form.Check 
                                type='checkbox'
                                onChange={(e) => handleCheckRetornoRevisao(e, item)}
                                checked={retornoRevisaoArr.filter(i => i.Peca === item.peca).length > 0}
                              />
                            </Form>
                          </td>
                          <td className={styles.tableLines}>{item.peca}</td>
                          <td className={styles.tableLines}>{item.material}</td>
                          <td className={styles.tableLines}>{item.descricao_material}</td>
                          <td className={styles.tableLines}>{item.cor_material}</td>
                          <td className={styles.tableLines}>{item.descricao_cor}</td>
                        </tr> 
                      )}) 
                  : null
                }
              </tbody>
            </Table>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseRetornoRevisao}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className={styles.modalBtn}
            onClick={handleProcessarRetornoRevisao}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Retorno - Laboratório */}
      <Modal
        show={showModalRetornoLaboratorio}
        onHide={handleCloseRetornoLaboratorio}
        backdrop="static"
        keyboard={false}
        //dialogClassName={styles.widthModal}
        size='xl'
        centered
      >
        <Modal.Header>
          <Modal.Title>Retorno - Laboratório</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do Pedido</Accordion.Header>
                { busca.length > 0 && acaoSelecionada['value'] === 'retorno' ?          
                  <Accordion.Body className={styles.infoBlockDisplay}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Ordem de produção:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.ordem_producao}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Ordem de serviço:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.ordem_servico}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Origem:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.origem}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Produto:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.produto}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Descrição produto:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.descricao_produto}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Tipo ordem produção:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.tipo_op}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Marca:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.marca}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Coleção:</span>
                      <span className={styles.fontBlue}>{linhaSelecionada.colecao}</span>
                    </div>
                  </div>
                </Accordion.Body> : null
                }
              </Accordion.Item>
            </Accordion>
          </article>

          <article className={styles.incTableInsertWrapper}>
            <div className={styles.tablesRow}>
              <div className={styles.tablesColumn}>
                <Form>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}></th>
                        <th className={styles.blockTitle}>SELECIONE O MATERIAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      { materiaisRetornoLab.length > 0 && acaoSelecionada['value'] === 'retorno' ? 
                          materiaisRetornoLab.map((item, index) => {
                            return (
                            <tr className={styles.tableLines} key={index}>
                              <td className={styles.tableLines}>
                                <Form.Check 
                                  type='radio'
                                  name='mat'
                                  onClick={() => handlePecasRetornoLab(item)}
                                  onChange={(e) => handleCheckMaterialRetornoLab(e, item)}
                                  checked={retornoLabMaterialEscolhido === item}
                                />
                              </td>
                              <td className={styles.tableLines}>{item.material}</td>
                            </tr>
                        )}) 
                        : null
                      }
                    </tbody>
                  </Table>
                </Form>
              </div>

              <div className={styles.tablesColumn}>
                <Form>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}></th>
                        <th className={styles.blockTitle}>SELECIONE A PEÇA</th>
                      </tr>
                    </thead>
                    <tbody>
                    { pecasRetornoLab.length > 0 && acaoSelecionada['value'] === 'retorno' ? 
                        pecasRetornoLab.map((item, index) => {
                          return (
                          <tr className={styles.tableLines} key={index}>
                            <td className={styles.tableLines}>
                              <Form.Check 
                                type='radio'
                                name='pec'
                                onChange={(e) => handleCheckPecaRetornoLab(e, item)}
                                checked={retornoLabPecaEscolhida === item}
                              />
                            </td>
                            <td className={styles.tableLines}>{item.peca}</td>
                          </tr>
                        )}) 
                      : null
                    }
                    </tbody>
                  </Table>
                </Form>
              </div>

              <div className={styles.tablesColumn}>
                <Form>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}></th>
                        <th className={styles.blockTitle}>SELECIONE O TESTE</th>
                      </tr>
                    </thead>
                    <tbody>
                      { testes.map((item, index) => {
                        return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>                     
                            <Form.Check 
                              type='checkbox'
                              name='tst'
                              onChange={(e) => handleCheckTesteRetornoLab(e, item)}
                              checked={retornoTestesSelecionados.filter(i => i.id_teste === item.id_teste).length > 0}
                            />
                          </td>
                          <td className={styles.tableLines}>{item.descricao_teste}</td>
                        </tr>
                        )}) 
                      }
                    </tbody>
                  </Table>
                </Form>
              </div>
            </div>
            <div style={{marginBottom: "20px"}}>
              <Button 
                variant='success' 
                style={{whiteSpace: "nowrap"}}
                onClick={incluirPecaRetornoLab}
              >Incluir material</Button>
            </div>
          </article>

          <article className={styles.incTableWrapper}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>MATERIAL</th>
                  <th className={styles.blockTitle}>PEÇA</th>
                  <th className={styles.blockTitle}>TESTE</th>
                </tr>
              </thead>
              <tbody>
                { retornoPecasIncluidas.length > 0 ? 
                    retornoPecasIncluidas.map((item, index) => {
                      const teste = testes.find(el => el.id_teste === item.IdTeste)
                      return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>
                            <Button 
                              style={{ backgroundColor:'white', border: 'white'}}
                              onClick={() => handleDeletePecaRetornoLab(item)}
                            >
                              <MaterialIcon
                                icon="delete" 
                                size={25} 
                                color='#BB271A'
                              />
                            </Button>
                          </td>
                          <td className={styles.tableLines}>{item.Material}</td>
                          <td className={styles.tableLines}>{item.Peca}</td>
                          <td className={styles.tableLines}>{teste.descricao_teste}</td>
                        </tr>
                    )})
                  : null
                }
              </tbody>
            </Table>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseRetornoLaboratorio}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className={styles.modalBtn}
            onClick={handleProcessarRetornoLaboratorio}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RevisaoLaboratorio;