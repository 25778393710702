import React from "react";
import { Button, Modal, Table, Accordion } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';

const ModalConfirmacaoRiscos = () => {

    const { showModalConfirmacaoRiscos, handleCloseModalConfirmacaoRiscos, linhaConfSelecionada, materiaisFinalizados } = useLancamentoCad();

    const riscos = materiaisFinalizados.filter(material => material.cad.material === linhaConfSelecionada.material && material.cad.cor === linhaConfSelecionada.cor_material)

    return (
        <Modal
            show={showModalConfirmacaoRiscos}
            onHide={handleCloseModalConfirmacaoRiscos}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Confirmar?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section style={{marginBottom: '20px'}}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Dados da Linha Selecionada</Accordion.Header>
                            <Accordion.Body>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Material</span>
                                            <span className={styles.fontBlue}>{linhaConfSelecionada.material}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm} style={{width: '300px'}}>
                                            <span>Descrição Material Serviço</span>
                                            <span className={styles.fontBlue}>{linhaConfSelecionada.desc_material}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Cor Material</span>
                                            <span className={styles.fontBlue}>{linhaConfSelecionada.cor_material}</span>
                                        </div>
                                    </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </section>
                
            
                <h5>Riscos</h5>
                <Table responsive style={{textAlign: 'center'}}>
                    <thead>
                        <tr>
                            <th>Risco</th>
                            <th>Grade</th>
                            <th>Comprimento do Risco</th>
                        </tr>
                    </thead>
                    <tbody>
                        { Object.keys(linhaConfSelecionada).length > 0 && riscos[0].riscos.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.risco}</td>
                                    <td>{item.Grade}</td>
                                    <td>{item.comprimentoRisco}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                <h5>Quantidade de Folhas</h5>
                <Table responsive style={{textAlign: 'center'}}>
                    <thead>
                        <tr>
                            <th>Grade</th>
                            <th>Folhas</th>
                        </tr>
                    </thead>
                    <tbody>
                        { Object.keys(linhaConfSelecionada).length > 0 && Object.entries(riscos[0].folhas).map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item[0]}</td>
                                    <td>{item[1]}</td>
                                </tr>
                            )
                        })} 
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseModalConfirmacaoRiscos}
                >
                    Voltar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmacaoRiscos;
