import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { usePriorizacaoOps } from '../../../../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext';
import styles from '../../../../styles/Corte/ControleEnfesto/PriorizacaoOps.module.scss';
import Select from 'react-select';
import { toDatetime } from '../../../../utils/dateUtils'

const ModalModoCorte = () => {

    const { linhasCorteManual, showModal, handleCloseModal, handleShowConfirmacao, dataPriorizacao, setDataPriorizacao, modoCorteData, modoCorteSelecionado, setModoCorteSelecionado, turnoCorteData, turnoCorteSelecionado, setTurnoCorteSelecionado } = usePriorizacaoOps();

    const today = new Date()
    const minDate = toDatetime(today.setDate(today.getDate() + 1)).slice(0, 10)
    const maxDate = toDatetime(today.setDate(today.getDate() + 2)).slice(0, 10)

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Insira as informações a seguir:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Data:</strong></Form.Label>
                            <Form.Control
                                type="date"
                                className={styles.formControl}
                                value={dataPriorizacao || ''}
                                onChange={e => setDataPriorizacao(e.target.value)}
                                min={minDate}
                                max={maxDate}
                            />
                        </Form.Group>
                    </Form>
                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Selecione o turno de corte:</strong></Form.Label>
                            <Select
                                className={styles.formControl}
                                closeMenuOnSelect={true}
                                value={turnoCorteSelecionado || ''}
                                options={turnoCorteData}
                                getOptionLabel={(option)=>option.TurnoCorte}
                                getOptionValue ={(option)=>option.IdTurnoCorte}
                                onChange={(e) => setTurnoCorteSelecionado(e)}
                            />
                        </Form.Group>
                    </Form>
                    { 
                        linhasCorteManual.length > 0 &&
                        <Form className={styles.formWrapperModal}>
                            <Form.Group className={styles.filterInputModal}>
                                <Form.Label><strong>Selecione o modo de corte:</strong></Form.Label>
                                <Select
                                    className={styles.formControl}
                                    closeMenuOnSelect={true}
                                    value={modoCorteSelecionado || ''}
                                    options={modoCorteData}
                                    getOptionLabel={(option)=>option.DescricaoModoCorte}
                                    getOptionValue ={(option)=>option.IdModoCorte}
                                    onChange={(e) => setModoCorteSelecionado(e)}
                                />
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModal}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleShowConfirmacao}
                    >
                        Avançar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalModoCorte;
