import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 

const MainTable = () => {

    const { handleShowGrade, loading, mainData, toggledClearRows, checklistColumns } = useDistribuicaoDemanda();

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                title
                columns={checklistColumns}
                data={mainData}
                pending={loading}
                rowClickedFn={handleShowGrade}
                clearSelectedRows={toggledClearRows}
            />
        </section>
    );
};

export default MainTable;
